import React from 'react'
import { navigate, Link } from 'gatsby'
import styled from 'styled-components'
import * as colors from './colors'
import * as typography from './typography'
import SelectIcon from './SelectIcon'

const StyledPagination = styled.div`
  align-items: baseline;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 1.5rem auto 2.5rem;
  max-width: 1600px; /* get from code */
  padding: 0 16px;
  width: 100%;
`

const Button = styled(Link)`
  ${typography.uiButton};
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  margin: 0 4px;
  min-width: 88px;
  padding 0 16px;
  transition: 0.33s all;
`

const Numbers = styled.div`
  ${typography.uiButton};
  background: none;
  border-radius: 2px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  margin: 0 4px;
  min-width: 88px;
  padding 0 16px;
  position: relative;
  transition: 0.33s all;

  svg {
    fill: ${colors.N750};
    margin: 0 0 0 8px;
    transition: 0.33s all;
  }
`

const Select = styled.select`
  ${typography.baseUI(14,14)};
  appearance: none;
  background: none;
  border: none;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 40px;
  line-height: 40px;
  margin: 0;
  max-width: 100%;
  position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  width: 100%;

  option {
    color: ${colors.text};
  }
  
  ::-ms-expand {
    display: none;
  }
`

const Pagination = (props) => {
  function changePage(e) {
    navigate(
      e.target.value
        ? `${props.context.paginationPath}/${e.target.value}`
        : `${props.context.paginationPath}/`
    )
  }

  return (
    <>
      {
        props.context.numberOfPages > 1 && (
          <StyledPagination>
            {/* <div>
              PAGES = {props.context.numberOfPages}
            </div> */}
            <Numbers>
              {props.context.humanPageNumber}{' '}
              <Select
                value={
                  props.context.humanPageNumber === 1
                    ? ``
                    : props.context.humanPageNumber
                }
                onChange={changePage}
              >
                {Array.from({ length: props.context.numberOfPages }, (_, ndx) => (
                  <option value={`${ndx === 0 ? `` : ndx + 1}`} key={`page${ndx + 1}`}>
                    {ndx + 1}
                  </option>
                ))}
              </Select>
              / {props.context.numberOfPages} <SelectIcon />
            </Numbers>
            <div>
              {props.context.previousPagePath && (
                <Button to={`${props.context.previousPagePath}`}>
                  <span>&larr;</span> Prev
                </Button>
              )}
              {props.context.nextPagePath && (
                <Button style={{ order: 3 }} to={`${props.context.nextPagePath}`}>
                  Next <span>&rarr;</span>
                </Button>
              )}
            </div>
          </StyledPagination>
        )
      }
    </>
  )
}

export default Pagination