import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import * as colors from './colors'
import * as typography from './typography'
import TopicBit from '../components/TopicBit'

const ArticleLink = styled(Link)`
  display: block;

  ${({ featured }) => featured && `
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 48px;
    grid-template-columns: repeat(3, 1fr);
  `}
`

const Thumbnail = styled(Img)`
  __border: 1px solid pink;
  background-color: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 4px;
  max-height: 56.25%;
  padding-top: calc(500/800 * 100%);
  position: relative;
  width: 100%;

  ${({ featured }) => featured && `
    grid-column-end: span 2;
  `}
`

const Details = styled.div`
  __margin-left: 16px;
`

const Article = styled.article`
  grid-column: span 2;

  h1 {
    ${typography.heading3}
    margin: 0;
  }
  p {
    ${typography.text};
    color: ${colors.subtleText};
    margin: 12px 0;
  }

  a, a:visited, a:active {
    text-decoration: none;
  }

  ${({ featured }) => featured && `
    grid-column: 1/-1;

    h1 {
      ${typography.heroHeading};
      margin: 0;
    }
    p {
      ${typography.heroText};
      color: ${colors.subtleText};
    }
  `}

  @media (max-width: 960px) {
    grid-column: 1/-1;
    
    ${Details} {
      margin: 12px 0;
    }

    ${({ featured }) => featured && `
      padding-bottom: 0;

      h1 {
        ${typography.heading3};
      }
      p {
        ${typography.text};      
      }
      a {
        display: block;
      }
    `}
  }
`

const PostCard = ({ featured, data }) => {
  return (
    <Article 
      role="listitem" 
      featured={featured}
    >
      <ArticleLink 
        to={`/${data.slug}`} 
        featured={featured}
      >
        <Thumbnail
          alt={data.title}
          fixed={data.heroImage.sm}
          featured={featured}
        />
        <Details
          featured={featured}
        >
          <TopicBit 
            key={data.topic.slug} 
            label={data.topic.slug}
          />
          <h1>{data.title}</h1>
          <p>{data.description.description}</p>
        </Details>
      </ArticleLink>
    </Article>
  )
}

PostCard.propTypes = {
  featured: PropTypes.bool,
  data: PropTypes.object.isRequired
}

PostCard.defaultProps = {
  featured: false,
}

export default PostCard
