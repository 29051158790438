import React from 'react'
import styled from 'styled-components'

const Component = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 48px;
  margin: 0 80px 24px 80px;

  @media (max-width: 960px) {
    margin-left: 16px;
    margin-right: 16px;
  }

  > article {
    margin-bottom: 0;
  }
  
  > article:last-child {
    margin-bottom: 32px;
  }
`

const PostCardList = ({children}) => {
  return (    
    <Component role="list">
      {children}
    </Component>
  )
}

export default PostCardList