import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Container from '../components/Container'
import Pagination from '../components/Pagination'
import PostCardList from '../components/PostCardList'
import PostCard from '../components/PostCard'
import * as typography from '../components/typography'

const TopicDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(6,1fr);
  grid-gap: 48px;
  margin: 0 80px 24px 80px;

  @media (max-width: 960px) {
    margin-left: 16px;
    margin-right: 16px;
  }

  h1 {
    ${typography.heading1};
    text-transform: capitalize;
  }
`

const TopicTemplate = ({ data, pageContext }) => {
  const slug = pageContext.slug
  const posts = data.allContentfulPost.edges

  return (
    <Layout>
      <SEO title={null} />  
      <Container>
        <TopicDetails>
          <h1>{slug}</h1>
        </TopicDetails>
        {/* <Pagination context={pageContext} /> */}
        <PostCardList>
          {
            posts.map(({ node: post }) => (
              <PostCard 
                key={post.id} 
                data={post}
              />              
            ))
          }
        </PostCardList>         
        <Pagination context={pageContext} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $skip: Int!, $limit: Int!) {
    allContentfulPost(
      filter: {topic: {slug: {eq: $slug}}}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          slug 
          description {
            description
          }         
          topic {
            slug
          }
          heroImage {
            title
            fluid(maxWidth: 1600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            sm: resize(width: 400) {
              src
            }
          }    
          publishDate(formatString: "MMMM Do, YYYY")
        }
      }
    }
  }
`

export default TopicTemplate
